import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import CardUser from './CardUser';
import { getUser } from '../api/services'
import { Skeleton, Button, notification, Tag, Space, Descriptions } from 'antd';
import { getItem, setItem } from '../utils/localStorageControl';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: text => <a>{text}</a>,
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    render: tags => (
      <>
        {tags.map(tag => {
          let color = tag.length > 5 ? 'geekblue' : 'green';
          if (tag === 'loser') {
            color = 'volcano';
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          );
        })}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space size="middle">
        <a>Invite {record.name}</a>
        <a>Delete</a>
      </Space>
    ),
  },
];
const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    tags: ['loser'],
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
];

export default function Dashboard(props) {

  const [user, setUser] = useState({})
  const [movilizadores, setMov] = useState([])
  const [lessons, setLessons] = useState([])
  const [payments, setPayments] = useState([])
  const [sales, setSales] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    const urlData = window.location.pathname;
    const idData = urlData.substr(1);
    console.log('idData', idData)
    if (idData) {
      setItem('userid', idData);
    }
    setLoading(false);
    getPayments();
  }, [props.userData])

  const [state, setstate] = useState({
    viewDetail: false,
    viewList: true,
    nombre: '',
    userActive: {},
    isPayment: true,
    isSales: false,
    isLessons: false,
  })

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      duration: 1,
      message: 'Notificación',
      description: description,
    });
  };

  const getPayments = async () => {
    setLoading(true);
    const userData = getItem('userid')
    if (!userData) {
      openNotificationWithIcon('error', 'No se cargo correctamente')
    }
    const result = await getUser(userData);
    setPayments(result.payments);
    setLessons(result.lessons);
    setSales(result.sales);
    setUser(result.student);
    openNotificationWithIcon('success', 'Se cargaron correctamente')
    setLoading(false);
    window.scrollTo(0, 0)
  }

  const Header = styled.div`
    padding-top: 10px
  `;

  const HeaderTitle = styled.div`
    font-size: 20px;
    font-weight: 600;
    align-items: center;
    text-align: center;
    margin: 10px;
  `;

  const SubTitle = styled.div`
    font-size: 16px;
    font-weight: 400;
    align-items: center;
    text-align: center;
    margin: 10px;
  `;


  const handleView = (data) => {
    setstate({ ...state, viewDetail: true, viewList: false, userActive: data });
  };

  const handlePayments = () => {
    setstate({
      ...state,
      isPayment: true,
      isSales: false,
      isLessons: false,
    });
  };

  const handleSales = () => {
    setstate({
      ...state,
      isPayment: false,
      isSales: true,
      isLessons: false,
    });
  };

  const handleLessons = () => {
    setstate({
      ...state,
      isPayment: false,
      isSales: false,
      isLessons: true,
    });
  };

  const handleList = () => {
    window.scrollTo(0, 0)
    setstate({ ...state, viewDetail: false, viewList: true });
  };

  return (
    <>
      <Header>
        <HeaderTitle>Dashboard de Usuario</HeaderTitle>
        <SubTitle>
          <div>{user.name}</div>
          <div>
            <img width="50" src={user.url_qr}></img>
          </div>
          <div></div>
          <Descriptions.Item style={{ padding: 2 }} label="Documento">{user.urlPdf ? <a href={user.urlPdf} target="_blank">Descargar Membresia</a> : ''}</Descriptions.Item>
        </SubTitle>
      </Header>
      {state.viewList && !loading && (
        <>
          <div
            style={{
              width: '100%',
              margin: 10,
              textAlign: 'center'
            }}>
            <Button style={{ margin: 10 }} onClick={() => handlePayments()} type="primary"  >
              Pagos
            </Button>
            <Button style={{ margin: 10 }} onClick={() => handleLessons()} type="primary"  >
              Clases
            </Button>
            <Button style={{ margin: 10 }} onClick={() => handleSales()} type="primary"  >
              Ventas
            </Button>
          </div>
          <div>
            {state.isPayment && (
              <>
                {payments.map((payment, index) => (
                  <CardUser key={index} data={payment} type={'payments'} />
                ))}
              </>
            )}
            {state.isLessons && (
              <>
                {lessons.map((payment, index) => (
                  <CardUser key={index} data={payment} type={'lessons'} />
                ))}
              </>
            )}
            {state.isSales && (
              <>
                {sales.map((payment, index) => (
                  <CardUser key={index} data={payment} type={'sales'} />
                ))}
              </>
            )}

          </div>
        </>
      )}
      <div style={{ padding: 20 }}>
        <Skeleton avatar loading={loading} active />
        <Skeleton avatar loading={loading} active />
        <Skeleton avatar loading={loading} active />
        <Skeleton avatar loading={loading} active />
        <Skeleton avatar loading={loading} active />
      </div>
    </>
  )
}
