import axios from 'axios';
import qs from 'querystring';
import { getItem, setItem} from '../utils/localStorageControl';
const urlBase = process.env.REACT_APP_BASE_URL;

const axiosConfig = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
};

const client = axios.create({
  baseURL: urlBase,
  headers: {
    Authorization: `Bearer ${getItem('token')}`,
    'Content-Type': 'application/json',
    'typeuser': 'user',
  },
});

const authHeader = () => ({
  Authorization: `Bearer ${getItem('token')}`,
});

async function verifyToken(data) {
  try {
    const loginResponse = await axios.post(
      `${urlBase}auth/login-token`,
      qs.stringify({user: data.user, password: data.password}),
      axiosConfig
    );
    console.log('loginResponse =>', loginResponse)
    setItem('token', loginResponse.data.tokens.access.token);
    setItem('user', loginResponse.data.userData);
    return loginResponse;
  } catch (error) {
    return {
      code: "FALSE",
      message: "No existe el usuario"
    };
  }
};

async function checkList(body) {
  try {
    const response = await client({
      method: 'POST',
      url: 'teachers/checkStudent',
      headers: {
        Authorization: `${authHeader()}`,
        'Content-Type': 'application/json',
      },
      data: body,
    });
    console.log('response', response)
    return response.data; 
  } catch (error) {
    return {
      code: "FALSE",
      message: "error"
    };
  }
};

async function getUser(params) {
  const user =await client({
    method: 'GET',
    url: `students/${params}`,
    headers: { ...authHeader() },
  });
  return user.data; 
};

async function getClass() {
  const user =await client({
    method: 'GET',
    url: `teachers/getClass`,
    headers: { ...authHeader() },
  });
  return user.data; 
};

async function getMov() {
  const movilizadores = await client({
    method: 'GET',
    url: 'getMovilization',
  });
  return movilizadores.data; 
};

async function getConv() {
  const movilizadores = await client({
    method: 'GET',
    url: 'getConv',
    headers: { ...authHeader() },
  });
  return movilizadores.data; 
};

async function setEncuesta(data) {
  try {
    const movilizadores = await client({
      method: 'POST',
      url: 'encuesta',
      headers: {
        Authorization: `${authHeader()}`,
        'Content-Type': 'application/x-www-form-urlencoded',
        'typeuser': 'user',
      },
      data: qs.stringify(data),
    });
    console.log('movilizadores', movilizadores)
    return movilizadores.data; 
  } catch (error) {
    return {
      code: "FALSE",
      message: "error"
    };
  }
};

client.interceptors.request.use(config => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = { ...headers, Authorization: `Bearer ${getItem('token')}` };
  return requestConfig;
});

export {
  verifyToken,
  getUser,
  checkList,
  getClass,
  getMov,
  getConv,
  setEncuesta
}
