import React from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import { notification } from 'antd';
import ContentData from './ContentData'
import Flexbox from 'flexbox-react';

function CardUser(props) {
  const params = props.data;
  const type = props.type;
  let title = '';
  switch (props.type) {
    case 'sales':
      title = params.saleTypes;
      break;
    case 'payments':
      title = params.descriptionPayment;
      break;
    case 'lessons':
      title = params.group_id?.name;
      break;
    default:
      break;
  }
  const viewDetail = () => {
    console.log('entro')
  };

  return (
    <Flexbox alignItems="center" justifyContent="center" flexDirection="column">
      <Card
        title={`${title}`}
        hoverable
        style={{
          margin: 10,
          maxWidth: 900,
          borderRadius: '1em'
        }}
      // actions={[
      //   <div >Encuestar</div>,
      // ]}
      >
        <ContentData content={params} type={type} />
      </Card>
    </Flexbox>
  )
}

export default CardUser
