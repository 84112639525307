import React, { useState, useEffect } from "react";
import "./App.css";
import Dashboard from "./components/Dashboard";
import Teacher from "./components/Teacher";

import { Layout } from "antd";
const { Footer, Content } = Layout;

function App() {
  const [userData, setUserData] = useState({});
  const [isTeacher, setIsTeacher] = useState();

  useEffect(async () => {
    const urlData = window.location.pathname;
    const idData = urlData.substr(1);
    if (idData === "maestro") {
      setIsTeacher(true);
    }
    console.log("idData", idData);
  }, []);

  return (
    <Layout>
      <Content>
        {isTeacher ? <Teacher></Teacher> : <Dashboard userData={userData} />}
      </Content>
      <Footer style={{ textAlign: "center" }}>
        <img
          width="150"
          src="https://storage.googleapis.com/ts3bloom.appspot.com/prestigio-img/logo-small.3c1ab57f.png"
        ></img>
      </Footer>
    </Layout>
  );
}

export default App;
