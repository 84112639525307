import React, { useState } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  notification,
  Descriptions,
  Tag,
  Result,
  List,
  Skeleton,
} from "antd";
import Flexbox from "flexbox-react";
import { verifyToken, getClass, checkList } from "../api/services";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export default function Teacher() {
  const [form] = Form.useForm();
  const [isLogin, setIsLogin] = useState(false);
  const [lessons, setLessons] = useState([]);

  const onFinish = async (value) => {
    const data = await verifyToken(value);
    if (data.status === 200) {
      const lessons = await getClass(data);
      setLessons(lessons);
      setIsLogin(true);
      openNotificationWithIcon("success", "Se valido correctamente");
    } else {
      onReset();
      openNotificationWithIcon("error", "Agregar token Valido");
    }
  };

  const checkData = async (body) => {
    console.log('body =>', body);
    const data = await checkList(body);
    const lessons = await getClass(data);
    setLessons(lessons);
    openNotificationWithIcon("success", "Se puso asistencia correctamente");
  };

  const openNotificationWithIcon = (type, description) => {
    notification[type]({
      duration: 2,
      message: "Notificación",
      description: description,
    });
  };

  const onFinishFailed = (errorInfo) => {
    openNotificationWithIcon("error", "Agregar token Valido");
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      {isLogin ? (
        <Flexbox
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <div></div>
          <h1 style={{ marginTop: 50 }}>Listado de clases</h1>
          {lessons.length == 0 && (
            <Result
              title="No se Encuentran Clases Asignadas"
              extra={
                <Button type="primary" key="console">
                  Salir
                </Button>
              }
            />
          )}
          <div>
            {lessons.map((lesson) => (
              <Card
                title={`${lesson.lesson.dateLesson} - ${lesson.lesson.group_id.name}`}
                hoverable
                style={{
                  margin: 10,
                  borderRadius: "1em",
                }}
                // actions={[<Button>TOMAR LISTA</Button>]}
              >
                <Descriptions>
                  <Descriptions.Item style={{ padding: 2 }} label="Semana">
                    {lesson.lesson.week}
                  </Descriptions.Item>
                  <Descriptions.Item
                    style={{ padding: 2 }}
                    label="Fecha de Clase"
                  >
                    {lesson.lesson.dateLesson}
                  </Descriptions.Item>
                  <Descriptions.Item style={{ padding: 2 }} label="Horario">
                    {lesson.lesson.timetable}
                  </Descriptions.Item>
                  <Descriptions.Item style={{ padding: 2 }} label="Estado">
                    {lesson.lesson.isActive ? (
                      <Tag color="green">ACTIVA</Tag>
                    ) : (
                      <Tag color="red">INACTIVA</Tag>
                    )}
                  </Descriptions.Item>
                </Descriptions>
                <h3 style={{textAlign:'center', padding:10}}> Listado de Alumnos</h3>
                <List
                  itemLayout="horizontal"
                  dataSource={lesson.lessonStudent}
                  renderItem={(item) => (
                    <List.Item actions={[,]}>
                      <Skeleton title={false} loading={item.loading} active>
                        <List.Item.Meta
                          title={
                            <div>
                              <div>{item.student_id?.name} </div>
                              {item.isPayment ? (
                                <Tag color="green">PAGADA</Tag>
                              ) : (
                                <Tag color="red">NO PAGADA</Tag>
                              )}
                            </div>
                          }
                        />
                        {!item.isAttendance && (
                          <List>
                            <Button type="primary" key="console" onClick={()=>{checkData(item)}}>
                              Check
                            </Button>
                          </List>
                        )}
                      </Skeleton>
                    </List.Item>
                  )}
                />
              </Card>
            ))}
          </div>
        </Flexbox>
      ) : (
        <Flexbox
          alignItems="center"
          justifyContent="center"
          width="100%"
          flexDirection="column"
          style={{ height: "100vh" }}
        >
          <div>
            <img
              width="250"
              src="https://storage.googleapis.com/ts3bloom.appspot.com/prestigio-img/logo-small.3c1ab57f.png"
            ></img>
          </div>
          <Card
            title="Login para Maestros"
            hoverable
            style={{
              width: "auto",
              borderRadius: 20,
            }}
          >
            <Form
              {...layout}
              form={form}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Usuario"
                name="user"
                rules={[
                  {
                    required: true,
                    message: "Agregar Usuario",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Agregar Password",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                  Entrar
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Flexbox>
      )}
    </>
  );
}
